import { IAppliedFilter, SessionState } from "../components/DashboardHeader/ISearch";

export const parseSloDate = (dateStr: string): Date | null => {
    const arr = dateStr.split('.');
    if (arr.length !== 3) return null;
    const numArr = arr.map((v) => Number(v));
    let date = new Date(numArr[2], numArr[1] - 1, numArr[0])
    date = new Date(date.getTime() + (-1 * date.getTimezoneOffset() * 60000))
    return date;
};
export const transformFilters = (appliedFilters: IAppliedFilter[]): URLSearchParams => {
    const urlParams: URLSearchParams = new URLSearchParams()

    appliedFilters.forEach((filter: IAppliedFilter) => {
        switch (filter[0].field) {
            case 'createdAt':
                if (filter.length === 3) {//user passed specific day or open interval at one side
                    let date = parseSloDate(filter[2].label);
                    if (!date) throw new Error(`Invalid date filter: value ${filter[2].label}`)

                    if (filter[1].field === "to") {
                        date = new Date(date.getTime() + 60 * 60 * 24 * 1000);
                    }
                    const dateStr = date.toISOString();

                    switch (filter[1].field) {
                        case 'to':
                            urlParams.append('created-at', `;${dateStr}`)
                            break;
                        case 'from':
                            urlParams.append('created-at', `${dateStr};`)
                            break;
                        case 'is':
                            const dateEnd = new Date(date.getTime() + 60 * 60 * 24 * 1000).toISOString();
                            urlParams.append('created-at', `${dateStr};${dateEnd}`)
                            break;
                        default: throw new Error(`Unknown date filter operation field: ${filter[1].field}`)
                    }
                } else if (filter.length === 5) {//user passed closed interval on both sides
                    let dateOne = parseSloDate(filter[2].label)
                    let dateTwo = parseSloDate(filter[4].label)

                    if (!dateOne) throw new Error(`Invalid date filter: value ${filter[2].label}`)
                    if (!dateTwo) throw new Error(`Invalid date filter: value ${filter[4].label}`)

                    if (filter[1].field === "from") {
                        dateTwo = new Date(dateTwo.getTime() + 60 * 60 * 24 * 1000);
                    } else {
                        dateOne = new Date(dateOne.getTime() + 60 * 60 * 24 * 1000);
                    }
                    const startDate = filter[1].field === "from" ? dateOne.toISOString() : dateTwo.toISOString()
                    const endDate = filter[1].field === "to" ? dateOne.toISOString() : dateTwo.toISOString()

                    urlParams.append('created-at', `${startDate};${endDate}`)
                } else throw new Error('Date filters should be of length 3 or 5.')
                break;
            case 'labels':
                let labelFilterValue = filter[2].label
                if (filter[1].field === "contains") labelFilterValue = `%${labelFilterValue}%`
                else if (filter[1].field === "startsWith") labelFilterValue = `${labelFilterValue}%`
                urlParams.append('label', labelFilterValue)
                break;

            case 'name':
                let namelFilterValue = filter[2].label
                if (filter[1].field === "contains") namelFilterValue = `%${namelFilterValue}%`
                else if (filter[1].field === "startsWith") namelFilterValue = `${namelFilterValue}%`
                urlParams.append('name', namelFilterValue)
                break;

            case 'search':
                urlParams.append('name', `${filter[1].label}`)
                break;

            case 'user':
                // admin only
                let userFilterValue = filter[2].label
                if (filter[1].field === "contains") userFilterValue = `%${userFilterValue}%`
                else if (filter[1].field === "startsWith") userFilterValue = `${userFilterValue}%`
                urlParams.append('created-by-username', userFilterValue)
                break;
            case 'group':
                let groupFilterValue = filter[2].label
                if (filter[1].field === "contains") groupFilterValue = `%${groupFilterValue}%`
                else if (filter[1].field === "startsWith") userFilterValue = `${groupFilterValue}%`
                urlParams.append('created-by-group', groupFilterValue)
                break;
            case 'sessionSource':
                // admin only
                let sessionSourceFilterValue = filter[2].label
                if (filter[1].field === "contains") sessionSourceFilterValue = `%${sessionSourceFilterValue}%`
                else if (filter[1].field === "startsWith") sessionSourceFilterValue = `${sessionSourceFilterValue}%`
                urlParams.append('source', sessionSourceFilterValue)
                break;
            case 'sessionStatus':
                // admin only
                let sessionStatusFilterValue = filter[2].label
                let sessionStatusFilterField = filter[1].field

                if (sessionStatusFilterValue === SessionState.DELETED) {
                    if (sessionStatusFilterField === "isNot") {
                        urlParams.append('is-discarded', 'false')
                    } else if (sessionStatusFilterField === "is") {
                        urlParams.append('is-discarded', 'true')
                    } else {
                        throw new Error(`Unknown uperation for filter SessionState. operation -> ${sessionStatusFilterField}`)
                    }
                } else {
                    if (sessionStatusFilterField === "isNot") {
                        Object.keys(SessionState).forEach(sessionState => {
                            if (sessionState !== sessionStatusFilterValue && sessionState !== SessionState.DELETED) {
                                urlParams.append('status', sessionState)
                            }
                        })
                    } else if (sessionStatusFilterField === "is") {
                        urlParams.append('status', sessionStatusFilterValue)
                    } else {
                        throw new Error(`Unknown uperation for filter SessionState. operation -> ${sessionStatusFilterField}`)
                    }
                }
                break;
            default:
                break;
        }
    });
    return urlParams;
};