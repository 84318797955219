import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../../redux/store"
import { IEditorSpeaker, SpeakerModalStage } from "../../../redux/store/IStore";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Add from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { getSpeakers } from '../../../api/speakersService';
import { updateSpeakerModal } from '../../../redux/features/app/app';
import { useDebounce } from '../../../hooks/useDebounce';

interface ISpeakerRowProps {
    speaker: IEditorSpeaker,
}
const SpeakerRow = ({ speaker }: ISpeakerRowProps) => {

    const dispatch = useAppDispatch();

    const editSpeaker = () => dispatch(updateSpeakerModal({ selectedSpeaker: speaker, modalStage: SpeakerModalStage.edit_speaker }))
    const deleteSpeaker = () => dispatch(updateSpeakerModal({ selectedSpeaker: speaker, modalStage: SpeakerModalStage.delete_speaker }))
    const addLocalSpeaker = () => dispatch(updateSpeakerModal({ selectedSpeaker: speaker, modalStage: SpeakerModalStage.add_new_speaker }))
    const updateChangedSpeaker = () => dispatch(updateSpeakerModal({ selectedSpeaker: speaker, modalStage: SpeakerModalStage.change_speaker }))

    const handleUserClick = () => {
        dispatch(updateSpeakerModal({ modalStage: SpeakerModalStage.change_speaker, selectedSpeaker: speaker }))
    }


    return (
        <div className="speaker_row_container">
            <p onClick={handleUserClick} className={`speaker_row_name${speaker.isLocal || speaker.hasChanged ? " speaker_row_name_local_or_changed" : ""}`}>{speaker.name}</p>
            <div>
                {!speaker.isLocal && !speaker.hasChanged && (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <EditIcon onClick={editSpeaker} sx={{ color: '#323232', ":hover": {cursor: 'pointer'} }} />
                        <DeleteOutlineIcon onClick={deleteSpeaker} sx={{ color: '#FF0000', ":hover": {cursor: 'pointer'} }} />
                    </div>
                ) || speaker.isLocal && (
                    <Add onClick={addLocalSpeaker} sx={{":hover": {cursor: 'pointer'}}} />
                ) || speaker.hasChanged && (
                    <SaveIcon onClick={updateChangedSpeaker} sx={{":hover": {cursor: 'pointer'}}} />
                )}
            </div>
        </div>
    )
}


const SpeakerModalList = () => {
    const dispatch = useAppDispatch();
    const oldSpeaker = useAppSelector(store => store.app.speakerModal.oldSpeaker)
    const [speakerNameQuery, setSpeakerNameQuery] = useState<string>('')
    const debouncedNameQuery = useDebounce(speakerNameQuery, 500, []);
    
    const updateSpeakerquery = (event: ChangeEvent<HTMLInputElement>) => setSpeakerNameQuery(event.target.value)

    const [speakerList, setSpeakerList] = useState<IEditorSpeaker[]>([]);

    const wrapper = async () => {
        try {
            const speakers = await getSpeakers(debouncedNameQuery, 10);
            setSpeakerList(speakers.data)
        } catch (error) {
            console.log(error)
        }
    }

    const addNewUser = () => dispatch(updateSpeakerModal({ modalStage: SpeakerModalStage.add_new_speaker }))
    const closeModal = () => dispatch(updateSpeakerModal({ blockKey: null, editorSelection: null, selectedSpeaker: undefined, modalStage: SpeakerModalStage.list, opened: false, oldSpeaker: undefined }))

    useEffect(() => {
        wrapper();
    }, [debouncedNameQuery])

    return (
        <>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
            <div>
                <div id="speaker_modal_header_wrapper">
                    <div>
                        <p id="speaker_modal_header_title">Govorci</p>
                        <p id="speaker_modal_header_subtitle">Izbrani govorec: {oldSpeaker && oldSpeaker.name}{oldSpeaker && oldSpeaker.selectedRole && ` | ${oldSpeaker.selectedRole}`}</p>
                    </div>
                    <button onClick={addNewUser} id="speaker_modal_add_new_user_container">
                        <Add sx={{ color: "#F2F2F2", width: 20 }} />
                    </button>
                </div>
                <input id="speaker_modal_search_input" value={speakerNameQuery} placeholder='Išči govorca' onChange={updateSpeakerquery} />
                <div id="speaker_list_container">
                    {speakerList.map(speaker => <SpeakerRow speaker={speaker} />)}
                </div>
            </div>
            <div id="speaker_modal_footer">
                <button id="spekaer_modal_close_button" onClick={closeModal}>ZAPRI</button>
            </div>
        </div>
        </>
    )
}

export default SpeakerModalList;