import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import {
  ContentBlock,
  Editor,
  EditorBlock,
  EditorState,
  DraftHandleValue,
  Modifier,
  SelectionState,
  convertToRaw,
  RawDraftEntityRange,
  RawDraftContentBlock,
  convertFromRaw,
  // RichUtils,
} from 'draft-js';
import SpeakerSection from './SpeakerSection';

import FormatBold from '@mui/icons-material/FormatBold';
import FormatItalic from '@mui/icons-material/FormatItalic';
import FormatUnderlined from '@mui/icons-material/FormatUnderlined';
import { SpeakerModalStage } from '../../redux/store/IStore';
import { IWordData } from './IEditor';
import { useAppDispatch } from '../../redux/store';
import { updateSpeakerModal } from '../../redux/features/app/app';
// import { getAllEntitiesKeysFromSelection } from './helpers/EditorHelpers';

interface IProps {
  editorState: EditorState;
  onEditorChange: (EditorState: EditorState) => void;
  handleBeforeInput?: (chars: string, editorState: EditorState, eventTimeStamp: number) => any;
  handlePastedText?: (text: string, html: string | undefined, editorState: EditorState) => DraftHandleValue;
  customBlockStyleFn?: any;
  readOnly: boolean;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  isEditorFocused?: any;
  placeHolder?: any;
  editorSelectionUpdateOnMount?: {
    offset: number;
    blockKey: string;
  } | null;
}

const SpeakerBlockWrapper = (props: any) => {
  const { block } = props;
  const dispatch = useAppDispatch();

  const speaker = block.getData().get('speaker'); //block. tezt, depth, type, key, data,

  // const isEmpty = !block.text.trim().length;
  const prevBlockKey = props.contentState.getKeyBefore(block.key);
  const prevBlock = props.contentState.getBlockForKey(prevBlockKey);
  const prevBlockData = prevBlock ? prevBlock.getData().get('speaker') : null;
  // const prevBlockIsSame = true ? false : prevBlockData ? prevBlockData.id === speaker.id : false;
  /*const prevBlockIsSame =
    speaker && prevBlockData !== null && prevBlockData && prevBlockData.id
      ? prevBlockData.id === speaker.id
      : false;*/

  return (
    <div className="SpeakerBlockWrapper_wrapper">
      {speaker && (
        <SpeakerSection
          isFirst={prevBlock ? false : true}
          speaker={speaker}
          onClick={(speaker) => {
            dispatch(
              updateSpeakerModal({
                editorSelection: null,
                modalStage: SpeakerModalStage.list,
                opened: true,
                oldSpeaker: speaker,
                blockKey: block.key,
              })
            );
          }}
        />
      )}
      <EditorBlock {...props} />
    </div>
  );
};

const blockRenderer = (contentBlock: ContentBlock) => {
  const type = contentBlock.getType();
  if (type === 'customBlock') {
    return {
      component: SpeakerBlockWrapper,
      // editable: true, needs to be unset to make readOnly work properly
      props: {},
    };
  }
};

// TO-DO:
const styleMap = {
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
};

const CustomEditor = forwardRef<Editor, IProps>(
  (
    {
      editorState,
      onEditorChange,
      handleBeforeInput,
      setEditorState,
      handlePastedText,
      customBlockStyleFn,
      readOnly,
      isEditorFocused,
      placeHolder,
      editorSelectionUpdateOnMount,
    },
    ref
  ) => {
    const handleReturn = (e: any, editorState: EditorState) => {
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();

      const blockKey = selectionState.getAnchorKey();

      const originalBlockData = contentState.getBlockForKey(blockKey).getData();
      //@ts-ignore
      const speakerData = originalBlockData.get('speaker');

      const newData = originalBlockData.set('isFirstInRange', false);

      if (speakerData) {
        const splitContentState = Modifier.splitBlock(editorState.getCurrentContent(), selectionState);
        const newBlockKey = splitContentState.getSelectionAfter().getStartKey();

        const newSelection1 = new SelectionState({
          anchorKey: newBlockKey,
          anchorOffset: 0,
          focusKey: newBlockKey,
          focusOffset: 0,
        });

        const a = Modifier.setBlockData(splitContentState, newSelection1, newData);
        const splitEditorState = EditorState.push(editorState, a, 'split-block');

        const newSelState = EditorState.forceSelection(splitEditorState, newSelection1);
        setEditorState(newSelState);

        return 'handled';
      } else {
        const {
          blocks,
          entityMap
        } = convertToRaw(editorState.getCurrentContent())

        const block = blocks.filter(block => block.key === selectionState.getStartKey())[0]
        //block.text = block.text.trim();

        if (block.entityRanges.length === 0) {
          return "not-handled"
        }
        
        const targetEntityRange = block.entityRanges.filter(range => selectionState.getStartOffset() >= range.offset && selectionState.getEndOffset() <= range.offset + range.length)[0]
        if (block.entityRanges[block.entityRanges.length - 1].key === targetEntityRange.key) {
          /*const blockKey = block.key
          const blockIndex = blocks.findIndex(block => block.key === blockKey)
          blocks.splice(blockIndex + 1, 0, {

          });*/
          return "not-handled"
        }

        if (selectionState.getStartOffset() === 0) {
          return "not-handled"
        }

        //This means that cursor was on the end of the token. When user sets cursor between two
        //different tokens a different strategy for entity datas needs to be used.

        //Here only split needs to be made. Probably no need for recalculation of end time, start time. Only offset for right token should be set to 0.
        if (targetEntityRange.offset + targetEntityRange.length === selectionState.getStartOffset()) {

          //TODO: Add update to the first token of the new block. SpaceBefore needs to be false.


          /*const leftEntity = entityMap[targetEntityRange.key];
          const rightEntity = entityMap[targetEntityRange.key + 1];

          
          const leftEntityData = ({...leftEntity.data as IWordData});
          const rightEntityData = ({...rightEntity.data as IWordData});

          rightEntityData.spaceBefore = false;*/
          
          const splittedAtEnd = Modifier.splitBlock(contentState, selectionState)

          const raw = convertToRaw(splittedAtEnd)

          let newBlockKey = ""
          for (let blockIndex = 1; blockIndex < raw.blocks.length; blockIndex++) {
            const previousBlock = {... raw.blocks[blockIndex - 1]};

            if (previousBlock.key !== block.key) continue;

            const currentBlock = {... raw.blocks[blockIndex]};


            newBlockKey = currentBlock.key
            previousBlock.text = previousBlock.text.trim();
            currentBlock.text = currentBlock.text.trim();
            const firstEntityRange = {... currentBlock.entityRanges[0]}
            const data = ({...raw.entityMap[firstEntityRange.key].data as IWordData})
            data.spaceBefore = false;
            raw.entityMap[firstEntityRange.key].data = data


            let currentOffset = 0;
            currentBlock.entityRanges = currentBlock.entityRanges.map(entityRange => {
              entityRange.offset = currentOffset
              if ((raw.entityMap[entityRange.key].data as IWordData).spaceBefore) {
                entityRange.offset++;
                currentOffset++;
              }
              currentOffset += entityRange.length

              return entityRange
            })

            raw.blocks[blockIndex] = currentBlock;
            raw.blocks[blockIndex - 1] = previousBlock;
          }

          const newState = EditorState.push(editorState, convertFromRaw(raw), 'change-block-data');
          const newSelectionState = SelectionState.createEmpty(newBlockKey).merge({
            anchorOffset: 0,
            focusOffset: 0,
          });
  
          const newEditorState = EditorState.forceSelection(newState, newSelectionState);
          setEditorState(newEditorState);
          return "handled";
        }

        const rightPartLength = targetEntityRange.offset + targetEntityRange.length - selectionState.getStartOffset()
        const leftPartLength = targetEntityRange.length - rightPartLength

        //TODO: Fix this deep copy
        const copyTargetEntityRange = JSON.parse(JSON.stringify(targetEntityRange)) as RawDraftEntityRange
        const entity = entityMap[targetEntityRange.key]
        const copyEntity = JSON.parse(JSON.stringify(entity))

        //handles string update
        const entityData = ({...entity.data as IWordData})
        entityData.updatedText = entityData.text.slice(0, leftPartLength).trim();
        const copyEntityData = ({...copyEntity.data as IWordData})
        copyEntityData.updatedText = copyEntityData.text.slice(leftPartLength - 1).trim();

        //time update
        const duration = entityData.endTime - entityData.startTime
        entityData.endTime = entityData.startTime + duration / 2
        copyEntityData.startTime = entityData.startTime + duration / 2
        
        //space before update
        copyEntityData.spaceBefore = false

        //entity map update
        targetEntityRange.length = leftPartLength
        copyTargetEntityRange.key = +targetEntityRange.key + 1
        copyTargetEntityRange.length = rightPartLength
        copyTargetEntityRange.offset = 0

        //update entityMap
        
        const updatedEntityMap = {}
        
        for (let entityIndex = 0; entityIndex < Object.keys(entityMap).length; entityIndex++) {
          if (entityIndex < +targetEntityRange.key) {
            updatedEntityMap[entityIndex] = entityMap[entityIndex]
          } else if (entityIndex === +targetEntityRange.key) {
            updatedEntityMap[entityIndex] = { type: "WORD", mutability: 'MUTABLE', data: entityData }
            updatedEntityMap[entityIndex + 1] = { type: "WORD", mutability: 'MUTABLE', data: copyEntityData }
          } else if (entityIndex > +targetEntityRange.key) {
            updatedEntityMap[entityIndex + 1] = entityMap[entityIndex]
          }
        }

        //update blocks
        //split text
        //set entityRanges
        //create new block, append remaining entities
        // add to blocks
        //recreate from raw.

        const x = Modifier.splitBlock(contentState, selectionState)

        const raw = convertToRaw(x)
        raw.entityMap = updatedEntityMap
        const updatedEntityRanges: RawDraftContentBlock[] = []
        //update all entityRanges
        let targetBlockFound = false
        let blockKeyToTarget = ""
        for (let blockIndex = 0; blockIndex < raw.blocks.length; blockIndex++) {
          if (targetBlockFound) {
            raw.blocks[blockIndex].entityRanges.forEach(entityRange => entityRange.key += 1)
            updatedEntityRanges.push(raw.blocks[blockIndex])
            continue;
          }
          
          if (raw.blocks[blockIndex].key === blockKey) {
            targetBlockFound = true;
            blockKeyToTarget = raw.blocks[blockIndex+1].key
          }

          updatedEntityRanges.push(raw.blocks[blockIndex])
        }
        
        const newContent = convertFromRaw(raw);
        const newState = EditorState.push(editorState, newContent, 'change-block-data');
        const newSelectionState = SelectionState.createEmpty(blockKeyToTarget).merge({
          anchorOffset: 0,
          focusOffset: 0,
        });

        const newEditorState = EditorState.forceSelection(newState, newSelectionState);
        setEditorState(newEditorState);
        return 'handled'
      }

      return 'not-handled';
    };

    // const toggleInlineStyles = (inlineStyle: string) => {
    //   const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
    //   const currentStyle = newState.getCurrentInlineStyle();
    //   const stylesToAdd: string[] = [];
    //   currentStyle.flatMap((a) => {
    //     if (a) {
    //       stylesToAdd.push(a);
    //     }
    //     return a;
    //   });

    //   const selection = editorState.getSelection();
    //   const se = getAllEntitiesKeysFromSelection(editorState, selection);

    //   se.forEach((ent) => {
    //     newState.getCurrentContent().mergeEntityData(ent.entityKey, { inlineStyles: stylesToAdd });
    //   });

    //   setEditorState(newState);
    //   if (ref) {
    //     //@ts-ignore
    //     ref.current.focus();
    //   }
    // };

    const cursorSet = useRef(false);
    useEffect(() => {
      if (!editorSelectionUpdateOnMount || cursorSet.current) return;

      setEditorState((curr) => {
        const sel = new SelectionState({
          anchorKey: editorSelectionUpdateOnMount.blockKey,
          anchorOffset: editorSelectionUpdateOnMount.offset,
          focusKey: editorSelectionUpdateOnMount.blockKey,
          focusOffset: editorSelectionUpdateOnMount.offset,
          hasFocus: true,
          isBackwards: false,
        });

        return EditorState.forceSelection(curr, sel);
      });
      cursorSet.current = true;
    }, [editorSelectionUpdateOnMount]);

    return (
      <>
        {/* <div style={{ marginBottom: 30 }}>
          <InlineStyleControls toggleInlineStyle={toggleInlineStyles} editorState={editorState} />
        </div> */}
        <Editor
          ref={ref}
          editorState={editorState}
          onChange={onEditorChange}
          handleBeforeInput={handleBeforeInput}
          blockStyleFn={customBlockStyleFn}
          handlePastedText={handlePastedText}
          readOnly={readOnly}
          blockRendererFn={blockRenderer}
          stripPastedStyles={true}
          customStyleMap={styleMap}
          placeholder={placeHolder}
          handleReturn={handleReturn}
          // onBlur={() => {
          //   console.log('Will blur');
          //  isEditorFocused && isEditorFocused.current = false;
          // }}
          // onFocus={() => {
          //   console.log('Will focus');
          //  isEditorFocused && isEditorFocused.current = true;
          // }}
        />
      </>
    );
  }
);

export const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon: FormatBold },
  { label: 'Italic', style: 'ITALIC', icon: FormatItalic },
  { label: 'Underline', style: 'UNDERLINE', icon: FormatUnderlined },
];

export const InlineStyleControls = ({
  editorState,
  toggleInlineStyle,
}: {
  editorState: EditorState;
  toggleInlineStyle: any;
}) => {
  // const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="editor_style_controls_wrapper">
      {INLINE_STYLES.map((type) => (
        <button
          key={type.label}
          onMouseDown={(e) => {
            toggleInlineStyle(type.style);
            e.stopPropagation();
            e.preventDefault();
          }}
          className="editor_style_control_button"
        >
          <type.icon style={{ width: '100%', height: '100%' }} />
        </button>
      ))}
    </div>
  );
};

export default memo(CustomEditor);
