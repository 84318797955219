import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { cloneDeep } from 'lodash';
import { IEditorSpeaker, SpeakerModalStage } from '../../../redux/store/IStore';
import { SelectionState } from 'draft-js';
import { updateSpeakerModal } from '../../../redux/features/app/app';
import Checkbox from '@mui/material/Checkbox';

interface IChangeRole {
    value: string;
    selected: boolean;
}

interface ISpeakerModalProps {
    replaceSpeaker: (newSpeaker: IEditorSpeaker, oldSpeaker: IEditorSpeaker | undefined, replaceAll: boolean, blockKey: string) => void;
    setSpeakerToSelection: (sel: SelectionState, speaker?: IEditorSpeaker) => void;
}
const SpeakerModalReplace = ({
    replaceSpeaker,
    setSpeakerToSelection
}: ISpeakerModalProps) => {
    const dispatch = useAppDispatch();

    const {
        blockKey,
        selectedSpeaker,
        editorSelection,
        oldSpeaker
    } = useAppSelector(state => state.app.speakerModal);

    const [roles, setRoles] = useState<IChangeRole[]>(selectedSpeaker ? selectedSpeaker.roles.map((role, index) => {
        return {
            value: role,
            selected: index === 0
        }
    }) : [])

    const [replaceAll, setReplaceAll] = useState<boolean>(false);

    const changeRole = (roleIndex: number) => {
        const copy = cloneDeep(roles)
        copy.forEach((role, index) => role.selected = index === roleIndex ? !role.selected : false)
        setRoles(copy)
    }

    const goBack = () => dispatch(updateSpeakerModal({ modalStage: SpeakerModalStage.list }))

    const swap = () => {
        const selectedRoles = roles.filter(role => role.selected)
        const selectedRole = selectedRoles.length > 0 ? selectedRoles[0].value : undefined
        if (!editorSelection && selectedSpeaker && blockKey) {
            /*updateSpeaker({
                speaker: { name: speaker.name },
                id: speaker.id,
                updateLastSelectedAt: true,
            });*/
            replaceSpeaker({ ...selectedSpeaker, selectedRole }, oldSpeaker, replaceAll, blockKey);
            dispatch(updateSpeakerModal({
                blockKey: null,
                editorSelection: null,
                selectedSpeaker: undefined,
                modalStage: undefined,
                opened: false,
                oldSpeaker: undefined,
            }))
            return;
        }

        if (editorSelection && selectedSpeaker) {
            setSpeakerToSelection(editorSelection, { ...selectedSpeaker, selectedRole });
            /*updateSpeaker({
                speaker: { name: speaker.name },
                id: speaker.id,
                updateLastSelectedAt: true,
            });*/
            dispatch(updateSpeakerModal({
                blockKey: null,
                editorSelection: null,
                selectedSpeaker: undefined,
                modalStage: undefined,
                opened: false,
                oldSpeaker: undefined,
            }))
            return;
        }

        /*if (handleSingleSpeakerMode.show) {
            const { name, labels } = newsSpeakerData;
            if (name.trim().length === 0) {
                setNewSpeakerErrors({
                    ...newSpeakerErrors,
                    name: 'Polje ne sme biti prazno',
                });
                return;
            }

            if (handleSingleSpeakerMode.selectedSpeaker) {
                updateSpeaker({ speaker: { name }, id: handleSingleSpeakerMode.selectedSpeaker.id });
            } else {
                mutate({ speaker: { name }, updateLastSelectedAt: true });
            }
        }*/
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <div>
                <p id="speaker_modal_header_title">Zamenjaj govorca</p>
                <p>Novi govorec {selectedSpeaker && selectedSpeaker.name}</p>

                <p>Vloga novega govorca</p>
                <div id="speaker_roles_container">
                    {roles.map((iterationRole, index) => {
                        return (
                            <button onClick={() => changeRole(index)} className={`speaker_role${iterationRole.selected ? " speaker_role_default" : ""}`}>
                                <p>{iterationRole.value}</p>
                            </button>
                        )
                    })}
                </div>

                <div id="switch_all_container">
                    <div>Zamenjaj vse</div>
                    <Checkbox
                        checked={replaceAll}
                        onChange={() => {
                            setReplaceAll(!replaceAll);
                        }}
                        value="replaceAll"
                        color="primary"
                    />
                </div>
            </div>

            <div id="speaker_modal_footer">
                <button id="spekaer_modal_close_button" onClick={goBack}>NAZAJ</button>
                <button id="spekaer_modal_save_button" onClick={swap}>ZAMENJAJ</button>
            </div>
        </div>
    )
}

export default SpeakerModalReplace;