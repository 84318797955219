import React from 'react';
import { deleteSpeaker } from "../../../api/speakersService";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { SpeakerModalStage } from "../../../redux/store/IStore";
import { updateSpeakerModal } from '../../../redux/features/app/app';

const SpeakerModalDelete = () => {
    const dispatch = useAppDispatch();
    const selectedSpeaker = useAppSelector(store => store.app.speakerModal.selectedSpeaker)

    const cancelSpeakerDelete = () => dispatch(updateSpeakerModal({ selectedSpeaker: undefined, modalStage: SpeakerModalStage.list }))

    const deleteSpeakerWrapper = async () => {
        if (!selectedSpeaker) return;

        try {
            const deleteResponse = await deleteSpeaker(selectedSpeaker.id);
            console.log(deleteResponse)
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <div>
                <p id="speaker_modal_header_title">Izbriši govorca</p>
                <p style={{ textWrap: 'wrap' }}>
                    Ali ste prepičani, da želite izbrisati govorca{selectedSpeaker && (<strong>{` ${selectedSpeaker.name}`}</strong>)}?
                </p>
            </div>
            <div id="speaker_modal_footer">
                <button id="spekaer_modal_close_button" onClick={cancelSpeakerDelete}>NE</button>
                <button id="spekaer_modal_save_button" onClick={deleteSpeakerWrapper}>DA</button>
            </div>
        </div>
    )
}

export default SpeakerModalDelete;