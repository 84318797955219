import { convertToRaw, EditorState } from 'draft-js';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { decorator } from '../components/Editor/helpers/EditorHelpers';
import {
  CurrentCommandModeEnums,
  ICursor,
  IFindCommandMode,
  IFixSpellCommandMode,
  ICommandModeSubs,
  IInsCommandMode,
  IWordDataLive,
  ITranscriptWord,
} from '../components/Editor/IEditor';
import { CursorTypesEnum } from '../components/Editor/LastPositionCursor';

import { EditorContextValue, EditorProviderProps } from './editorTypes';
import { IV3RecievedToken } from '../components/Libraries/ILibraries';
import { SpeakersChangeList } from '../redux/store/IStore';
import { useAppDispatch } from '../redux/store';
import { setEndSpeakerList } from '../redux/features/app/app';

export const EditorContext = createContext<EditorContextValue>({
  liveWordData: {
    finalsBlocks: [],
    lastInterim: '',
  },
  transcriptData: [],
  fixSpellCommandMode: {
    currentCommandMode: CurrentCommandModeEnums.INIT,
    isSpellingOn: false,
    currentSpelledWord: [],
    selectedWordOriginalText: null,
    selectedWordIndexes: null,
    textToFetch: '',
    numberOfWordsInFixMode: 1,
    prevWordsHistory: null,
    hasAddedNewWordInSpellingMode: false,
    wordDataSpellingOnlyMode: undefined,
  },
  cursor: {
    cursorType: CursorTypesEnum.NORMAL,
    cursorPosition: null,
  },
  commandModeSubs: {
    subsState: 'loading',
    subs: null,
    selectedSubText: undefined,
  },
  findCommandMode: {
    findCommandModeOn: false,
    selectedWordIndexes: null,
    searchedText: null,
    selectedWordData: null,
    selectedRangeLength: 1,
    isInsertMode: null,
  },
  insCommandMode: {
    isOn: false,
  },
  commandModeMoveSubs: null,
  footerTr: { finalStr: '', interimStr: '' },
  editorState: EditorState.createEmpty(),
  setFooterTr: () => {},
  setCommandModeMoveSubs: () => {},
  setLiveWordData: () => {},
  setTranscriptData: () => {},
  setFixSpellCommandMode: () => {},
  setCursor: () => {},
  setCommandModeSubs: () => {},
  setFindCommandMode: () => {},
  cancelFixCommandMode: () => {},
  changeCursor: () => {},
  setEditorState: () => {},
  setInsCommandMode: () => {},
});

// TO-DO
// export const editorReducer = (state: ) => {
//     switch (action.type) {

//     }
// }

export const EditorProvider: React.FC<EditorProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator)); //EditorState.createEmpty(decorator)
  const [liveWordData, setLiveWordData] = useState<IWordDataLive>({
    finalsBlocks: [],
    lastInterim: '',
  });
  const [transcriptData, setTranscriptData] = useState<Array<ITranscriptWord[]>>([]);
  const [fixSpellCommandMode, setFixSpellCommandMode] = useState<IFixSpellCommandMode>({
    currentCommandMode: CurrentCommandModeEnums.INIT,
    isSpellingOn: false,
    currentSpelledWord: [],
    selectedWordOriginalText: null,
    selectedWordIndexes: null,
    textToFetch: '',
    numberOfWordsInFixMode: 1,
    prevWordsHistory: null,
    hasAddedNewWordInSpellingMode: false,
    wordDataSpellingOnlyMode: undefined,
  });
  const [insCommandMode, setInsCommandMode] = useState<IInsCommandMode>({
    isOn: false,
  });
  const [findCommandMode, setFindCommandMode] = useState<IFindCommandMode>({
    findCommandModeOn: false,
    selectedWordIndexes: null,
    searchedText: null,
    selectedWordData: null,
    selectedRangeLength: 1,
    isInsertMode: null,
  });
  const [cursor, setCursor] = useState<ICursor>({
    cursorType: CursorTypesEnum.NORMAL,
    cursorPosition: null,
  });
  const [footerTr, setFooterTr] = useState<{ finalStr: string; interimStr: string }>({
    finalStr: '',
    interimStr: '',
  });
  const [commandModeSubs, setCommandModeSubs] = useState<ICommandModeSubs>({
    subsState: 'loading',
    subs: null,
    selectedSubText: undefined,
  });
  const [commandModeMoveSubs, setCommandModeMoveSubs] = useState<'L' | 'R' | null>(null);

  const cancelFixCommandMode = useCallback(() => {
    if (insCommandMode.isOn) {
      setInsCommandMode({ isOn: false });
      return;
    }

    let newB = [...liveWordData.finalsBlocks];
    if (fixSpellCommandMode.prevWordsHistory && fixSpellCommandMode.selectedWordIndexes) {
      if (
        newB[newB.length - 1].words[newB[newB.length - 1].words.length - 1] &&
        newB[newB.length - 1].words[newB[newB.length - 1].words.length - 1].wasMergedWithCorrCommand
      ) {
        newB[newB.length - 1].words.splice(-1, 1);
        newB[newB.length - 1].words = [
          ...newB[newB.length - 1].words,
          ...fixSpellCommandMode.prevWordsHistory,
        ];
      }
    } else if (fixSpellCommandMode.selectedWordIndexes) {
      newB[fixSpellCommandMode.selectedWordIndexes[0]].words[
        fixSpellCommandMode.selectedWordIndexes[1]
      ].updatedText = undefined;
      newB[fixSpellCommandMode.selectedWordIndexes[0]].words[
        fixSpellCommandMode.selectedWordIndexes[1]
      ].text = fixSpellCommandMode.selectedWordOriginalText
        ? fixSpellCommandMode.selectedWordOriginalText
        : '';
    } else if (
      fixSpellCommandMode.currentCommandMode === CurrentCommandModeEnums.SPELL_MODE_ONLY &&
      fixSpellCommandMode.hasAddedNewWordInSpellingMode
    ) {
      newB[newB.length - 1].words.pop();
    }
    setLiveWordData((curr) => {
      return {
        lastInterim: curr.lastInterim,
        finalsBlocks: newB,
      };
    });
    setFixSpellCommandMode({
      currentCommandMode: CurrentCommandModeEnums.INIT,
      selectedWordIndexes: null,
      currentSpelledWord: [],
      isSpellingOn: false,
      selectedWordOriginalText: null,
      textToFetch: '',
      numberOfWordsInFixMode: 1,
    });
  }, [fixSpellCommandMode, liveWordData, insCommandMode]);

  const changeCursor = useCallback(
    (cursorType: CursorTypesEnum) => {
      setCursor((prev) => {
        return {
          ...prev,
          cursorType,
        };
      });
    },
    [setCursor]
  );

  useEffect(() => {
    const speakerRaw = convertToRaw(editorState.getCurrentContent())

    const speakerChangeListStart: SpeakersChangeList = []
    speakerRaw.blocks.forEach(block => {
      if (block.data && block.data.speaker) {
        speakerChangeListStart.push({
          speaker: block.data.speaker,
          startTime: speakerRaw.entityMap[block.entityRanges[0].key].data.startTime,
          endTime: speakerRaw.entityMap[block.entityRanges[block.entityRanges.length - 1].key].data.endTime
        })
      }
    })

    dispatch(setEndSpeakerList(speakerChangeListStart))
  }, [editorState])

  return (
    <EditorContext.Provider
      value={{
        liveWordData,
        transcriptData,
        fixSpellCommandMode,
        cursor,
        commandModeSubs,
        findCommandMode,
        commandModeMoveSubs,
        footerTr,
        editorState,
        insCommandMode,
        setInsCommandMode,
        setEditorState,
        setFixSpellCommandMode,
        setCursor,
        setLiveWordData,
        setTranscriptData,
        setCommandModeSubs,
        setFindCommandMode,
        cancelFixCommandMode,
        changeCursor,
        setCommandModeMoveSubs,
        setFooterTr,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export default EditorContext;
