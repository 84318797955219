import React from "react";
import './new_speaker_modal.css';
import Modal from "../../Home/Modal";
import SpeakerModalEdit from "./SpeakerModalEdit";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { IEditorSpeaker, SpeakerModalStage } from "../../../redux/store/IStore";
import SpeakerModalCreate from "./SpeakerModalCreate";
import SpeakerModalDelete from "./SpeakerModalDelete";
import SpeakerModalList from "./SpeakerModalList";
import { updateSpeakerModal } from "../../../redux/features/app/app";
import { SelectionState } from "draft-js";
import SpeakerModalReplace from "./SpeakerModalReplace";

interface ISpeakerModalProps {
    replaceSpeaker: (newSpeaker: IEditorSpeaker, oldSpeaker: IEditorSpeaker | undefined, replaceAll: boolean, blockKey: string) => void;
    setSpeakerToSelection: (sel: SelectionState, speaker?: IEditorSpeaker) => void;
}
const SpeakerModal = ({
    replaceSpeaker,
    setSpeakerToSelection,
}: ISpeakerModalProps) => {
    const dispatch = useAppDispatch();
    const modalStage = useAppSelector(store => store.app.speakerModal.modalStage);
    const opened = useAppSelector(store => store.app.speakerModal.opened);

    const closeModal = () => dispatch(updateSpeakerModal({
        blockKey: null,
        editorSelection: null,
        selectedSpeaker: undefined,
        oldSpeaker: undefined,
        modalStage: SpeakerModalStage.list,
        opened: false,
    }))

    return (
        <Modal showClose={false} visible={opened} closeModal={closeModal} title={''}>
            <div style={{height: '35vh'}}>
                {modalStage === SpeakerModalStage.list && <SpeakerModalList />}
                {modalStage === SpeakerModalStage.delete_speaker && <SpeakerModalDelete />}
                {modalStage === SpeakerModalStage.add_new_speaker && <SpeakerModalCreate />}
                {modalStage === SpeakerModalStage.edit_speaker && <SpeakerModalEdit />}
                {modalStage === SpeakerModalStage.change_speaker && <SpeakerModalReplace replaceSpeaker={replaceSpeaker} setSpeakerToSelection={setSpeakerToSelection} />}
            </div>
        </Modal>
    )
}

export default SpeakerModal;