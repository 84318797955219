import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import useEditor from '../../hooks/useEditor';
import { cloneDeep } from 'lodash';
import { ISegment, updateSpeakerVector } from '../../api/speakersService';
import { patchEditorContentV30, unlockSession } from '../../api/SessionsService';
import { convertToRaw } from 'draft-js';
import { setHomeFlowState } from '../../redux/features/app/app';


interface IUpdateSpeaker {
    id: number;
    name: string;
    selected: boolean;
    segments: ISegment[];
}
interface ISpeakerVectorsModalProps {
    onClose: () => void;
}
const SpeakerVectorsModal = ({
    onClose
}: ISpeakerVectorsModalProps) => {
    const dispatch = useAppDispatch();
    const { editorState } = useEditor();

    const [speakersToUpdate, setSpeakersToUpdate] = useState<IUpdateSpeaker[]>([])
    const editorLock = useAppSelector(store => store.app.editorLock)
    const endSpeakerList = useAppSelector(store => store.app.endSpeakerList)
    const startSpeakerList = useAppSelector(store => store.app.startSpeakerList)


    const closeWrapper = async () => {
        if (!editorLock.sessionId)  return;
        const speakersListToUpdate = speakersToUpdate.filter(speaker => speaker.selected).map(speaker => {
            return { id:speaker.id, segments: speaker.segments, sessionId: editorLock.sessionId as number }
        })

          
          const requests = speakersListToUpdate.map(params => updateSpeakerVector(params.id, params.sessionId, params.segments));
          
          try {
            await Promise.all(requests);

            if (editorState && editorLock && editorLock.sessionLockKey && editorLock.editTicket && editorLock.sessionId) {
                await patchEditorContentV30(
                    { rawContentState: convertToRaw(editorState.getCurrentContent()) },
                    editorLock.sessionId,
                    editorLock.editTicket,
                    editorLock.sessionLockKey,
                );
    
                await unlockSession(editorLock.sessionId, editorLock.sessionLockKey)
            }
            dispatch(setHomeFlowState({ liveFlowInProgress: false }));
            onClose();
          } catch (error) {
            console.log(error);
          }
    }

    const toggleSpeaker = (id: number) => {
        const copy = cloneDeep(speakersToUpdate);
        copy.forEach(speaker => {
            if (speaker.id === id) {
                speaker.selected = !speaker.selected
            }
        })
        setSpeakersToUpdate(copy)
    }

    useEffect(() => {
        const oldTimesMap = new Map<number, { startTime: number, endTime: number }[]>();

        startSpeakerList.forEach(item => {
          const times = oldTimesMap.get(item.speaker.id) || [];
          times.push({ startTime: item.startTime, endTime: item.endTime });
          oldTimesMap.set(item.speaker.id, times);
        });
      
        // Find new times that are not in old times or overlapping
        const speakerMap = new Map<number, IUpdateSpeaker>();
      
        endSpeakerList.forEach(newItem => {
          const oldTimes = oldTimesMap.get(newItem.speaker.id) || [];
      
          // Check if there is any overlap with old times
          const isOverlapping = oldTimes.some(oldTime => newItem.startTime < oldTime.endTime && newItem.endTime > oldTime.startTime);
      
          if (!isOverlapping) {
            if (!speakerMap.has(newItem.speaker.id)) {
              speakerMap.set(newItem.speaker.id, {
                id: newItem.speaker.id,
                name: newItem.speaker.name,
                selected: false,
                segments: []
              });
            }
      
            speakerMap.get(newItem.speaker.id)!.segments.push({
              startTimeMs: Math.floor(newItem.startTime * 1000),
              endTimeMs: Math.floor(newItem.endTime * 1000)
            });
          }
        });

        setSpeakersToUpdate(Array.from(speakerMap.values()))        
    }, [])

    return (
        <div style={{ backgroundColor: "#F2F2F2", padding: "12px 24px" }}>
            <h1>Posodobitev vektorjev</h1>

            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th style={{ textAlign: "left" }}>Left Column</th>
                        <th style={{ textAlign: "right" }}>Right Column</th>
                    </tr>
                </thead>
                <tbody>
                    {speakersToUpdate.map(speaker => {
                        return (
                            <tr key={speaker.id}>
                                <td style={{ textAlign: "left" }}>{speaker.name}</td>
                                <td style={{ textAlign: "right" }}>
                                    <input onChange={() => toggleSpeaker(speaker.id)} type="checkbox" id={speaker.name} name={speaker.name} checked={speaker.selected} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <div>
                <button onClick={closeWrapper}>KONČAJ</button>
            </div>
        </div>
    )
}

export default SpeakerVectorsModal