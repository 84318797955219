import { EditorState, SelectionState } from 'draft-js';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useRef } from 'react';
import { IModal, ModalVariantsEnums } from './IEditor';
import { ClearIcon } from '../Icons/ClearIcon';
import EditIcon from '@mui/icons-material/Edit';
import { IEditorSpeaker, IStore, SpeakersModalTypeEnum } from '../../redux/store/IStore';
import '../../styles/css/main_modals.css';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { deleteSpeaker, getSpeakers, patchSpeaker, postNewSpeaker } from '../../api/speakersService';
import { useDebounce } from '../../hooks/useDebounce';
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Checkbox from '@mui/material/Checkbox';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setSpeakersModal, updateSpeakerModal } from '../../redux/features/app/app';

interface IProps {
  editorState: EditorState;
  setEditorState: React.Dispatch<SetStateAction<EditorState>>;
  setModal: React.Dispatch<SetStateAction<IModal>>;
  replaceSpeaker: (newSpeaker: IEditorSpeaker, oldSpeaker: IEditorSpeaker, replaceAll: boolean, blockKey: string) => void;
  setSpeakerToSelection: (sel: SelectionState, speaker?: IEditorSpeaker) => void;
}

const SpeakersModalContent = ({
  editorState,
  setEditorState,
  setModal,
  replaceSpeaker,
  setSpeakerToSelection,
}: IProps) => {
  const queryClient = useQueryClient();
  const [search, setSearch] = useState<string>('');
  const [lastHandledSpeakerId, setLastHandledSpeakerId] = useState<number>(-1);
  const debouncedSearchString = useDebounce(search, 500, []);
  const [limit, setLimit] = useState<number>(20);
  const {
    data: speakersData,
    error: speakersError,
    isLoading: isLoadingSpeakers,
  } = useQuery(['speakers', { searchTerm: debouncedSearchString, limit: limit }], async (a) => {
    //@ts-ignore
    const { limit, searchTerm } = a.queryKey[1];
    const { data } = await getSpeakers(searchTerm, limit);
    return data;
  });

  const {
    mutate: deleteSpeakerFn,
    data: dataDelete,
    error: errorDelete,
    isLoading: isLoadingDelete,
  } = useMutation(deleteSpeaker, {
    onSuccess: () => {
      queryClient.invalidateQueries('speakers');
    },
  });

  const {
    mutate: updateSpeaker,
    data: dataUpdate,
    error: errorUpdate,
    isLoading: isLoadingUpdate,
  } = useMutation(patchSpeaker, {
    onSuccess: () => {
      queryClient.invalidateQueries('speakers');
    },
  });

  const [newsSpeakerData, setNewSpeakerData] = useState<{
    id: number;
    name: string;
    labels?: any;
  }>({ name: '', labels: [], id: -1 });
  const [newSpeakerErrors, setNewSpeakerErrors] = useState<{
    name: boolean | string;

    labels: boolean | string[];
  }>({ name: false, labels: false });

  const {
    mutate,
    data: dataAdd,
    error: errorAdd,
    isLoading: isLoadingAdd,
  } = useMutation(postNewSpeaker, {
    onSuccess: (data, variables) => {
      setLastHandledSpeakerId(data.data.id);

      queryClient.invalidateQueries('speakers');
    },
  });

  useEffect(() => {
    if (lastHandledSpeakerId !== -1) {
      const f = speakersData.find((s) => s.id === lastHandledSpeakerId);
      if (f !== undefined && f !== null) {
        setSelectedSpeaker(f);
      }
    }
  }, [lastHandledSpeakerId, speakersData]);

  const [selectedSpeaker, setSelectedSpeaker] = useState<IEditorSpeaker | null>(null);
  const [handleSingleSpeakerMode, setHandleSingleSpeakerMode] = useState<{
    show: boolean;
    selectedSpeaker: IEditorSpeaker | null;
  }>({ show: false, selectedSpeaker: null });

  const {
    speaker,
    blockKey,
    editorSelection,
  } = useAppSelector(state => state.app.speakersModal);

  const [replaceAll, setReplaceAll] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const showConfirmOpts = selectedSpeaker !== null || handleSingleSpeakerMode.show;

  useEffect(() => {
    if (dataAdd || dataUpdate || dataDelete) {
      enqueueSnackbar(`Govorec uspešno ${dataAdd ? 'dodan' : dataUpdate ? 'posodobljen' : 'izbrisan'}`, {
        variant: 'success',
      });
      if (dataAdd) {
        setHandleSingleSpeakerMode({ show: false, selectedSpeaker: null });
        setHandleSingleSpeakerMode((curr) => {
          return { ...curr, show: false };
        });
      } else {
        setHandleSingleSpeakerMode({ show: false, selectedSpeaker: null });
      }
    }
  }, [dataAdd, dataUpdate, dataDelete]);

  useEffect(() => {
    if (errorAdd || errorUpdate || errorDelete) {
      enqueueSnackbar(
        `Prišlo je do napake pri ${
          errorAdd ? 'dodajanju' : errorUpdate ? 'posodabljanju' : 'brisanju'
        } govorca`,
        {
          variant: 'error',
        }
      );
    }
  }, [errorAdd, errorDelete, errorUpdate]);

  useEffect(() => {
    if (!handleSingleSpeakerMode.selectedSpeaker) {
      return setNewSpeakerData((curr) => {
        return {
          ...curr,
          id: -1,
          name: '',
          labels: [],
        };
      });
    }

    setNewSpeakerData({ ...handleSingleSpeakerMode.selectedSpeaker });
  }, [handleSingleSpeakerMode]);

  useEffect(() => {
    return () => {
      dispatch(
        updateSpeakerModal({
          blockKey: null,
          editorSelection: null,
          selectedSpeaker: undefined,
          modalStage: undefined,
          opened: false,
        })
        /*setSpeakersModal({
          editorSelection: null,
          modalType: SpeakersModalTypeEnum.NORMAL,
          showModal: false,
          speaker: null,
          blockKey: null,
        })*/
      );
    };
  }, []);

  const closeModalAndReset = () => {
    setModal({ variant: ModalVariantsEnums.EXIT, show: false });
  };

  const handleClickConfirm = () => {
    if (!editorSelection && selectedSpeaker && blockKey && speaker) {
      updateSpeaker({
        speaker: { name: selectedSpeaker.name },
        id: selectedSpeaker.id,
        updateLastSelectedAt: true,
      });
      replaceSpeaker(selectedSpeaker, speaker, replaceAll, blockKey);
      closeModalAndReset();
      return;
    }

    if (editorSelection && selectedSpeaker) {
      setSpeakerToSelection(editorSelection, selectedSpeaker);
      updateSpeaker({
        speaker: { name: selectedSpeaker.name },
        id: selectedSpeaker.id,
        updateLastSelectedAt: true,
      });
      closeModalAndReset();
      return;
    }

    if (handleSingleSpeakerMode.show) {
      const { name, labels } = newsSpeakerData;
      if (name.trim().length === 0) {
        setNewSpeakerErrors({
          ...newSpeakerErrors,
          name: 'Polje ne sme biti prazno',
        });
        return;
      }

      if (handleSingleSpeakerMode.selectedSpeaker) {
        updateSpeaker({ speaker: { name }, id: handleSingleSpeakerMode.selectedSpeaker.id });
      } else {
        mutate({ speaker: { name }, updateLastSelectedAt: true });
      }
    }
  };

  return (
    <div className="replace_words_modal_wrapper" style={{ maxWidth: 300, minHeight: 400 }}>
      <div>
        <div
          className="replace_words_modal_selected_phrase"
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: handleSingleSpeakerMode.show ? 5 : '',
            flexDirection: 'row',
          }}
        >
          <span style={{ position: 'relative' }}>
            {handleSingleSpeakerMode.show && handleSingleSpeakerMode.selectedSpeaker
              ? 'Posodobi govorca'
              : handleSingleSpeakerMode.show
              ? 'Nov govorec'
              : 'Govorci'}

            {blockKey && (
              <span
                style={{
                  left: 0,
                  position: 'absolute',
                  top: 'calc(100% - 5px)',
                  fontSize: 12,
                  whiteSpace: 'pre',
                }}
              >
                Menjava za: {speaker?.name}
              </span>
            )}
          </span>
          {!handleSingleSpeakerMode.show && (
            <button
              onClick={() => {
                setHandleSingleSpeakerMode({ selectedSpeaker: null, show: true });
              }}
              className="addpronunciation_btn"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 0,
              }}
            >
              <div />
              <div />
            </button>
          )}
        </div>
        {!handleSingleSpeakerMode.show && (
          <>
            <TextField
              value={search}
              FormHelperTextProps={{
                className: 'helper_text',
              }}
              style={{
                width: '100%',
                fontFamily: 'Roboto',
                marginBottom: 15,
              }}
              placeholder="Išči govorca"
              margin="dense"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
            />
            {isLoadingSpeakers && (
              <LinearProgress
                style={{
                  height: 2,
                  width: '100%',
                }}
                color="secondary"
              />
            )}
          </>
        )}
        {handleSingleSpeakerMode.show && (
          <HandleSingleSpeaker
            newSpeakerErrors={newSpeakerErrors}
            setNewSpeakerErrors={setNewSpeakerErrors}
            newsSpeakerData={newsSpeakerData}
            setNewSpeakerData={setNewSpeakerData}
          />
        )}
        {!handleSingleSpeakerMode.show && (
          <SpeakersMode
            speakersError={speakersError}
            deleteSpeakerFn={deleteSpeakerFn}
            speakersData={speakersData}
            selectedSpeaker={selectedSpeaker}
            setSelectedSpeaker={setSelectedSpeaker}
            setHandleSingleSpeakerMode={setHandleSingleSpeakerMode}
          />
        )}
      </div>

      {!handleSingleSpeakerMode.show && showConfirmOpts && !editorSelection && (
        <div className="checkbox_wrapper" style={{ border: 'none', marginTop: 30 }}>
          <div>Zamenjaj vse</div>
          <Checkbox
            checked={replaceAll}
            onChange={() => {
              setReplaceAll(!replaceAll);
            }}
            value="replaceAll"
            color="primary"
          />
        </div>
      )}

      <div className="modal_buttons_wrapper" style={{ marginTop: '35px' }}>
        <button
          type="button"
          onClick={() =>
            handleSingleSpeakerMode.show
              ? setHandleSingleSpeakerMode({ show: false, selectedSpeaker: null })
              : setModal({ variant: ModalVariantsEnums.EXIT, show: false })
          }
          className="modal_button_disaggre"
          style={selectedSpeaker === null && !handleSingleSpeakerMode.show ? { marginRight: 0 } : {}}
        >
          {handleSingleSpeakerMode.show && handleSingleSpeakerMode.selectedSpeaker
            ? 'Prekliči'
            : handleSingleSpeakerMode.show
            ? 'Nazaj'
            : 'Zapri'}
        </button>
        {showConfirmOpts && (
          <button
            type="button"
            onClick={handleClickConfirm}
            className="modal_button_aggre"
            disabled={isLoadingAdd || isLoadingSpeakers || isLoadingUpdate}
          >
            {handleSingleSpeakerMode.show && handleSingleSpeakerMode.selectedSpeaker
              ? 'Potrdi'
              : handleSingleSpeakerMode.show
              ? 'Dodaj'
              : blockKey
              ? 'Zamenjaj'
              : 'Izberi'}
          </button>
        )}
      </div>
    </div>
  );
};

function HandleSingleSpeaker({ newSpeakerErrors, setNewSpeakerErrors, setNewSpeakerData, newsSpeakerData }) {
  const fnRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fnRef.current?.focus();
  }, []);

  useEffect(() => {
    if (newSpeakerErrors.name && newsSpeakerData.name.length !== 0) {
      setNewSpeakerErrors({ ...newSpeakerErrors, name: false });
    }
  }, [newSpeakerErrors, newsSpeakerData.name]);
  return (
    <div>
      <TextField
        value={newsSpeakerData.name}
        FormHelperTextProps={{
          className: 'helper_text',
        }}
        inputRef={fnRef}
        error={newSpeakerErrors.name ? true : false}
        style={{
          width: '100%',
          fontFamily: 'Roboto',
          marginTop: 20,
        }}
        placeholder="Vnesite ime"
        margin="dense"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setNewSpeakerData((prev) => {
            return {
              ...prev,
              name: e.target.value,
            };
          })
        }
        helperText={newSpeakerErrors.name || ''}
      />
      {/* <TextField
        value={newsSpeakerData.labels}
        FormHelperTextProps={{
          className: 'helper_text',
        }}
        // inputRef={replaceRef}
        error={newSpeakerErrors.labels ? true : false}
        style={{
          width: '100%',
          fontFamily: 'Roboto',
          marginTop: 20,
        }}
        placeholder="Labele"
        margin="dense"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setNewSpeakerData((prev) => {
            return {
              ...prev,
              labels: e.target.value,
            };
          })
        }
        helperText={newSpeakerErrors.labels || ''}
      /> */}
    </div>
  );
}

function SpeakersMode({
  speakersData,
  selectedSpeaker,
  deleteSpeakerFn,
  setSelectedSpeaker,
  setHandleSingleSpeakerMode,
  speakersError,
}) {
  if (speakersError) return <span>Prišlo je do napake v povezavi z zalednim sistemom</span>;
  if (speakersData && speakersData.length === 0) return <span>Iskani govorec ne obstaja</span>;
  return (
    <div
      className="show_scrollbar"
      style={{ width: '100%', overflowY: 'auto', maxHeight: 250, paddingRight: 10 }}
    >
      {speakersData &&
        speakersData.length &&
        speakersData.map((r, i) => (
          <div
            key={i.toString()}
            style={{
              color: '#333333',

              width: '100%',
              position: 'relative',
              marginTop: '10px',
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: '45px',
              whiteSpace: 'pre',
            }}
          >
            <button
              style={{
                cursor: 'pointer',
                fontFamily: 'Roboto',
                fontSize: '18px',
                borderBottom: r.id === selectedSpeaker?.id ? '1px solid #2b8dff' : '',
                minHeight: 20,
              }}
              onClick={() => setSelectedSpeaker(r)}
            >
              {r.name}
            </button>
            <div>
              <button
                onClick={() => {
                  setHandleSingleSpeakerMode({ show: true, selectedSpeaker: r });
                }}
                style={{
                  color: '#707070',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: '50%',
                  right: 30,
                  transform: 'translateY(-50%)',
                }}
              >
                <EditIcon width="18px" />
              </button>
              <button
                onClick={() => {
                  deleteSpeakerFn(r.id);
                }}
                style={{
                  color: '#707070',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  transform: 'translateY(-50%)',
                }}
              >
                <ClearIcon width="18px" />
              </button>
            </div>
          </div>
        ))}
    </div>
  );
}

export default SpeakersModalContent;
