import axiosInstance from './axios';
import { AxiosResponse } from 'axios';

import { API } from './endpoints';
import { ISpeaker } from '../components/Editor/IEditor';
import { config } from '../config';

const backendUrl: string = config.backendApiUrl;
const baseUrl = `${backendUrl}/${API}/speakers`;

export const getSpeakers = (search?: string, limit?: number): Promise<AxiosResponse<any>> => {
  let parameters = ""
  if (search && search.length > 0) {
    parameters += `?search=${search}`
  }
  if (limit) {
    if (parameters.length > 0) {
      parameters += `&limit=${limit}`
    } else {
      parameters += `?limit=${limit}`
    }
  }
  return axiosInstance.get(`${baseUrl}${parameters}`);
};

export const getSpeaker = (id: number): Promise<AxiosResponse<any>> => {
  return axiosInstance.get(`${baseUrl}/${id}`);
};

/*export const deleteSpeaker = (id: number): Promise<AxiosResponse<any>> => {
  return axiosInstance.delete(`${baseUrl}/${id}`);
};*/

interface ISpeakerPost {
  name: string;
  roles: string[];
  updateLastSelectedAt: boolean;
}
export const addSpeaker = (newSpeaker: ISpeakerPost) => {
  return axiosInstance.post(
    baseUrl,
    newSpeaker,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}

export const updateSpeakerSpeaker = (id: number, updatedSpeaker: Partial<ISpeakerPost>) => {
  return axiosInstance.patch(
    `${baseUrl}/${id}`,
    updatedSpeaker,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}

export const deleteSpeaker = (deleteSpeakerId: number) => {
  return axiosInstance.delete(`${baseUrl}/${deleteSpeakerId}`);
}

export const postNewSpeaker = (data: {
  speaker: Partial<ISpeaker>;
  updateLastSelectedAt?: boolean;
}): Promise<AxiosResponse<any>> => {
  const { speaker, updateLastSelectedAt } = data;
  return axiosInstance.post(
    baseUrl,
    {
      ...speaker,
      updateLastSelectedAt: updateLastSelectedAt || false,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const patchSpeaker = (data: {
  speaker: Partial<ISpeaker>;
  id: number;
  updateLastSelectedAt?: boolean;
}): Promise<AxiosResponse<any>> => {
  const { speaker, id, updateLastSelectedAt } = data;
  return axiosInstance.patch(
    `${baseUrl}/${id}`,
    {
      ...speaker,
      updateLastSelectedAt: updateLastSelectedAt || false,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export interface ISegment {
  endTimeMs: number,
  startTimeMs: number,
}
export const updateSpeakerVector = (id: number, sessionId: number, segments: ISegment[]) => {
  
  return axiosInstance.patch(
    `${baseUrl}/${id}/id-vector`,
    {
      segments,
      sessionId,
    }
  );
}
